<template>
  <div class="profitView">
    <div class="hz_form">
      <div class="buy_fabu_mod">
        <div class="checkin_notice">
          <div class="checkin_notice_header">
            <van-image :src="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProfit } from '@/api/utils';
export default {
  data() {
    return {
      detail: {},
      name: 'Profit',
      image: require('@/assets/images/profit-' + this.$lang + '.jpg'),
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    //获取收益海报
    GetProfit() {
      getProfit({ lang: this.$lang })
        .then((response) => {
          this.detail = response.result;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang="stylus">
.profitView
  .hz_form
    .buy_fabu_mod
      overflow: hidden
      background-size: 100% 100%
      .checkin_notice
        .checkin_notice_header
          font-size: 0.42rem
          text-align: center
          line-height: 0.42rem
          margin-bottom: 0.2rem
          font-weight: 700
      .checkin_notice_content
        font-size: 0.36rem
        color: $grayTextColor
        line-height: 0.4rem
        letter-spacing: 0.015rem
</style>
